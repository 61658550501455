// Generated by Framer (838580a)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
import * as localizedValues from "./lKktZ5Lc_-0.js";
const MaterialFonts = getFonts(Material);

const cycleOrder = ["g14hJFs8H", "hlXdyXXjI"];

const serializationHash = "framer-d2M9u"

const variantClassNames = {g14hJFs8H: "framer-v-149tsv9", hlXdyXXjI: "framer-v-qqd8wv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Default: "g14hJFs8H", Select: "hlXdyXXjI"}

const getProps = ({height, icon, iconborder, iconColor, id, width, ...props}) => { return {...props, E2WBPobfH: iconColor ?? props.E2WBPobfH ?? "rgb(102, 102, 255)", R1WfG4pkt: icon ?? props.R1WfG4pkt ?? "Home", sYh5qcrMa: iconborder ?? props.sYh5qcrMa ?? {borderColor: "var(--token-e786d274-1fd4-44b2-8bb2-53d1a18b7911, rgb(82, 116, 255))", borderStyle: "solid", borderWidth: 0.5}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "g14hJFs8H"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;icon?: string;iconColor?: string;iconborder?: Record<string, any>; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, R1WfG4pkt, E2WBPobfH, sYh5qcrMa, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "g14hJFs8H", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-149tsv9", className, classNames)} data-border data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"g14hJFs8H"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(33, 33, 33, 0.1)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-cb8c315f-aa79-46d0-9894-796d4f3c486e, rgb(248, 248, 248))", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, boxShadow: "inset 0px 3px 4px 0px rgba(255, 255, 255, 0.1)", ...style}} variants={{hlXdyXXjI: {"--border-bottom-width": `${sYh5qcrMa.borderBottomWidth ?? sYh5qcrMa.borderWidth}px`, "--border-color": sYh5qcrMa.borderColor, "--border-left-width": `${sYh5qcrMa.borderLeftWidth ?? sYh5qcrMa.borderWidth}px`, "--border-right-width": `${sYh5qcrMa.borderRightWidth ?? sYh5qcrMa.borderWidth}px`, "--border-style": sYh5qcrMa.borderStyle, "--border-top-width": `${sYh5qcrMa.borderTopWidth ?? sYh5qcrMa.borderWidth}px`}}} {...addPropertyOverrides({hlXdyXXjI: {"data-framer-name": "Select"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1n9yxf1-container"} layoutDependency={layoutDependency} layoutId={"sWwBJNCmw-container"}><Material color={E2WBPobfH} height={"100%"} iconSearch={R1WfG4pkt} iconSelection={"Home"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"sWwBJNCmw"} layoutId={"sWwBJNCmw"} mirrored={false} selectByList={false} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-d2M9u.framer-1vca5hw, .framer-d2M9u .framer-1vca5hw { display: block; }", ".framer-d2M9u.framer-149tsv9 { height: 24px; overflow: visible; position: relative; width: 24px; }", ".framer-d2M9u .framer-1n9yxf1-container { bottom: 4px; flex: none; left: 4px; position: absolute; right: 4px; top: 4px; }", ".framer-d2M9u[data-border=\"true\"]::after, .framer-d2M9u [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"hlXdyXXjI":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"R1WfG4pkt":"icon","E2WBPobfH":"iconColor","sYh5qcrMa":"iconborder"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerlKktZ5Lc_: React.ComponentType<Props> = withCSS(Component, css, "framer-d2M9u") as typeof Component;
export default FramerlKktZ5Lc_;

FramerlKktZ5Lc_.displayName = "Category Elements/Category Icon";

FramerlKktZ5Lc_.defaultProps = {height: 24, width: 24};

addPropertyControls(FramerlKktZ5Lc_, {variant: {options: ["g14hJFs8H", "hlXdyXXjI"], optionTitles: ["Default", "Select"], title: "Variant", type: ControlType.Enum}, R1WfG4pkt: {defaultValue: "Home", placeholder: "Menu, Wifi, Box…", title: "Icon", type: ControlType.String}, E2WBPobfH: {defaultValue: "rgb(102, 102, 255)", title: "IconColor", type: ControlType.Color}, sYh5qcrMa: {defaultValue: {borderColor: "var(--token-e786d274-1fd4-44b2-8bb2-53d1a18b7911, rgb(82, 116, 255))", borderStyle: "solid", borderWidth: 0.5}, title: "iconborder", type: ControlType.Border}} as any)

addFonts(FramerlKktZ5Lc_, [{explicitInter: true, fonts: []}, ...MaterialFonts], {supportsExplicitInterCodegen: true})